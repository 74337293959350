import * as React from "react"
import { FaXmark } from "react-icons/fa6"
import { ButtonLink } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Link } from "@allied/react-web/Router"
import { useAutoPosition } from "@allied/react-web/hooks"
import { ButtonBack } from "../components/Navigation"
import { Breadcrumb } from "../components/Breadcrumb"
import { ComparationService } from "../services/local/Comparation"
import { ListingService } from "../services/api/Listing"

const productService = new ListingService({
  host: `${process.env.REACT_APP_API_HOST}`
})
const comparationService = new ComparationService(productService)

type CompareItem = {
  slug: string
  name: string
  thumbnail: any
  brief?: string
  description?: string
  principals: string[]
  brands: string[]
  types: string[]
}

export default function Compare(): React.JSX.Element {
  const [compareItems, setCompareItems] = React.useState<CompareItem[]>([])

  React.useEffect(() => {
    async function searchItem() {
      const searchItem = await comparationService.SearchItem({})
      if (searchItem.error) {
        return
      }

      setCompareItems(searchItem.data.items)
    }

    searchItem()
  }, [])

  const removeItem = (slug: string) => {
    async function removeItem(slug: string) {
      const removeItem = await comparationService.RemoveItem({
        slug
      })
      if (removeItem.error) {
        return
      }

      setCompareItems((prevState) => {
        return prevState.filter(product => {
          return product.slug !== slug
        })
      })
    }

    removeItem(slug)
  }

  function cleanText(text: string): string {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  }

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Compare Products",
          description: "Compare our list of products to see which products suit you best"
        }}
      </Meta>
      <Container size="md">
        <div className="my-6 flex flex-wrap flex-row gap-4">
          <ButtonBack>
            Back
          </ButtonBack>
          <Breadcrumb appendClassNames="font-roboto-mono font-light text-lg">
            {{
              items: [
                {
                  content: <>
                    <Link to="/" className="text-aglogis-primary">
                      Home
                    </Link>
                  </>
                },
                {
                  content: <>Compare Products</>,
                }
              ]
            }}
          </Breadcrumb>
        </div>

        {
          compareItems.length === 0 ?
            (
              <div className="my-10 flex flex-col justify-center items-center text-center gap-6">
                <div>
                  <h1 className="text-2xl font-semibold">No products to compare</h1>
                  <p className="text-xl">Add products to compare</p>
                </div>
                <ButtonLink to="/products">Explore Product</ButtonLink>
              </div>
            )
            :
            (
              compareItems.length === 1 ?
                <div className="my-10 flex flex-col justify-center items-center text-center gap-6">
                  <div>
                    <h1 className="text-2xl font-semibold">Add more products to compare</h1>
                    <p className="text-xl">Compare at least 2 products</p>
                  </div>
                  <ButtonLink to="/products">Explore Product</ButtonLink>
                </div>
                : null
            )
        }

        {
          compareItems.length > 0 &&
          <div className="my-4 grid grid-cols-12 gap-4">
            {
              compareItems.map((compareItem, i: number) => (
                <div key={`compare-item-${i}`} className="col-span-12 md:!col-span-6 lg:!col-span-4">
                  <div className="w-full h-auto p-5 rounded-2xl border border-gray-400">
                    <div className="relative">
                      <button className="absolute top-2.5 right-2.5"
                        onClick={() => removeItem(compareItem.slug)}>
                        <FaXmark className="w-6 h-6" aria-hidden="true" />
                      </button>
                      <img className="p-5 rounded-2xl border border-gray-400"
                        src={compareItem.thumbnail}
                        alt={compareItem.name}
                        style={{
                          width: "100%",
                          height: "400px",
                        }}
                      />
                    </div>
                    <div className="my-4">
                      <Link to={`/products/${compareItem.slug}`} className="text-2xl lg:!text-3xl font-bold">
                        {cleanText(compareItem.name)}
                      </Link>
                      <p className="text-gray-500 text-base md:!text-lg">
                        {compareItem.brief}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        }
      </Container>
    </>
  )
}
