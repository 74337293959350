import * as React from "react"
import { FaMagnifyingGlass, FaXmark } from "react-icons/fa6"
import { Link } from "react-router-dom"
import { ListingService } from "../../services/api/Listing"

const productService = new ListingService({
  host: `${process.env.REACT_APP_API_HOST}`
})

type SearchItem = {
  name: string
  slug: string
}

export function ProductSearch(): React.JSX.Element {
  const [products, setProducts] = React.useState<SearchItem[]>([])
  const [keyword, setKeyword] = React.useState("")

  const clearKeyword = () => {
    setProducts([])
    setKeyword("")
  }

  React.useEffect(() => {
    if (keyword.length < 3) {
      setProducts([])
      return
    }

    async function searchProduct({ keyword }: { keyword: string }) {
      const searchProduct = await productService.SearchProduct({
        page: 1,
        total_items: 15,
        keyword
      })
      if (searchProduct.error) {
        return
      }

      setProducts(searchProduct.data.items)
    }

    searchProduct({
      keyword
    })
  }, [keyword])

  return (
    <div className="relative flex flex-col justify-center font-roboto-mono font-light">
      <div className="flex flex-row gap-2 rounded-2xl bg-white border border-aglogis-primary py-3 px-4 h-11">
        <input className="w-full h-full outline-none"
          type="text"
          placeholder="Search for any products"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)} />
        <div className="flex justify-center items-center cursor-pointer p-2">
          {
            products.length === 0 ?
              <FaMagnifyingGlass className="w-4 h-4" aria-hidden="true" />
              :
              <FaXmark className="w-4 h-4" aria-hidden="true" onClick={clearKeyword} />
          }
        </div>
      </div>
      <div className="absolute left-0 right-0 max-h-48 bg-white rounded-md overflow-y-auto"
        style={{ top: "calc(100% + 10px)" }}>
        {
          products.length > 0 &&
          <ul className="text-aglogis-primary">
            {
              products.map((product, i: number) => {
                return (
                  <li key={`search-item-${i}`} className="p-1.5 hover:bg-gray-200">
                    <Link to={`/products/${product.slug}`}>
                      {product.name}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        }
      </div>
    </div>
  )
}
