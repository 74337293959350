import * as React from "react"
import { FaWhatsapp, FaTelegram, FaViber } from "react-icons/fa6"
import { Container } from "@allied/react-web/Container"
import { Checkbox } from "@allied/react-web/Check"
import { Button } from "@allied/react-web/Button"
import { Field, FieldNotif } from "@allied/react-web/Field"
import { Image } from "@allied/react-web/Image"
import { InputText, InputNumber, InputPhone, InputEmail } from "@allied/react-web/Input"
import { Link } from "@allied/react-web/Router"
import { Textarea } from "@allied/react-web/Textarea"
import { Meta } from "@allied/react-web/Meta"
import { useValidator, Validator, useAutoPosition, useRecaptcha } from "@allied/react-web/hooks"
import { toast } from "../components/Toast"
import { ContactService } from "../services/api/Contact"
import ReactGA from "react-ga4"
import container1 from "./../assets/image/containers/container1.jpg"
import container2 from "./../assets/image/containers/container2.jpg"
import container3 from "./../assets/image/containers/container3.jpg"
import wechatQr from "./../assets/image/wechat_qrcode.png"
import botimQr from "./../assets/image/botim_qrcode.png"

const contactService = new ContactService({
  host: process.env.REACT_APP_API_HOST
})

export default function ContainerEnquiry(): React.JSX.Element {
  const [containers, setContainers] = React.useState([
    {
      name: "20 GP",
      thumbnail: container1,
      featured: true,
      selected: false,
      quantity: 0,
    },
    {
      name: "40 GP",
      selected: false,
      quantity: 0,
    },
    {
      name: "40 HC",
      thumbnail: container3,
      featured: true,
      selected: false,
      quantity: 0,
    },
    {
      name: "20 RF",
      thumbnail: container2,
      featured: true,
      selected: false,
      quantity: 0,
    },
    {
      name: "40 RF",
      selected: false,
      quantity: 0,
    },
    {
      name: "20 RFHC",
      selected: false,
      quantity: 0,
    },
    {
      name: "40 RFHC",
      selected: false,
      quantity: 0,
    },
    {
      name: "20 OT",
      selected: false,
      quantity: 0,
    },
    {
      name: "40 OT",
      selected: false,
      quantity: 0,
    },
    {
      name: "20 FR",
      selected: false,
      quantity: 0,
    },
    {
      name: "40 FR",
      selected: false,
      quantity: 0,
    },
    {
      name: "20 TK",
      selected: false,
      quantity: 0,
    },
    {
      name: "20 PF",
      selected: false,
      quantity: 0,
    },
  ])
  const [types, setTypes] = React.useState([
    {
      name: "As Is",
      selected: false,
    },
    {
      name: "Brand New",
      selected: false,
    },
    {
      name: "Cargo Worthy",
      selected: false,
    }
  ])
  const [contacts] = React.useState([
    {
      name: "Whatsapp",
      icon: <FaWhatsapp className="w-5 h-5" aria-hidden="true" />,
      url: `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}`,
      target: "_blank"
    },
    {
      name: "Telegram",
      icon: <FaTelegram className="w-5 h-5" aria-hidden="true" />,
      url: `https://t.me/${process.env.REACT_APP_TELEGRAM_NUMBER}`,
      target: "_blank"
    },
    {
      name: "Viber",
      icon: <FaViber  className="w-5 h-5" aria-hidden="true" />,
      url: `viber://chat/?number=${process.env.REACT_APP_VIBER_NUMBER}`,
      target: "_blank"
    }
  ])
  const [enquiryForm, setEnquiryForm] = React.useState({
    loading: false,
    formType: "ContainerForm",
    name: "",
    email: "",
    phone: "",
    message: "",
    company: "",
    address: "",
    postal: "",
    containerItems: [],
    containerTypes: []
  })
  const [enquiryNotif, setEnquiryNotif] = React.useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    company: "",
    address: "",
    postal: "",
    containerItems: "",
    containerTypes: "",
  })
  const [enquiryValidator] = useValidator({
    name: Validator.string().required().min(3).max(256),
    email: Validator.string().required().email().min(3).max(256),
    phone: Validator.string().required().phone("SG").min(5).max(17).trim(),
    message: Validator.string(),
    company: Validator.string().required().min(3).max(256),
    address: Validator.string().required().min(3).max(512),
    postal: Validator.string().required().min(3).max(128),
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setEnquiryForm({ ...enquiryForm, [name]: value })
  }

  async function submitEnquiry(form: typeof enquiryForm & { verificationToken: string }) {
    toast.info(
      "Submiting your enquiry, please wait a moment",
      { className: "toast-mesasge" }
    )
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: true,
      }
    })
    const sendEnquiry = await contactService.SendEnquiry(form)
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        loading: false,
      }
    })
    if (sendEnquiry.error) {
      console.error("An error occurred while submitting the form", sendEnquiry.error)
      ReactGA.event({
        category: "Contact",
        action: "Email",
        label: "Failed Email",
      })
      toast.error(
        `Failed send enquiry: ${sendEnquiry.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        name: "",
        email: "",
        phone: "",
        message: "",
        company: "",
        address: "",
        postal: "",
        containerItems: [],
        containerTypes: [],
      }
    })
    setContainers((prevState) => {
      return prevState.map((container) => {
        return {
          ...container,
          selected: false,
          quantity: 0
        }
      })
    })
    setTypes((prevState) => {
      return prevState.map((type) => {
        return {
          ...type,
          selected: false,
        }
      })
    })
    ReactGA.event({
      category: "Contact",
      action: "Email",
      label: "Successful Email",
    })
    toast.success(
      "Enquiry Submitted Successfully. Our team will get back to you shortly",
      { className: "toast-mesasge" }
    )
  }

  const handleEnquirySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()

      setEnquiryNotif({
        name: "",
        email: "",
        phone: "",
        message: "",
        company: "",
        address: "",
        postal: "",
        containerItems: "",
        containerTypes: "",
      })

      const validateRes = enquiryValidator.validate({
        name: enquiryForm.name,
        email: enquiryForm.email,
        phone: enquiryForm.phone,
        message: enquiryForm.message,
        company: enquiryForm.company,
        address: enquiryForm.address,
        postal: enquiryForm.postal,
      })
      if (validateRes.error) {
        const err = validateRes.error

        if (err instanceof Validator.ValidationError) {
          setEnquiryNotif((prevState) => {
            return {
              ...prevState,
              [err.path]: err.errors.join("\n")
            }
          })
        } else {
          console.warn("unexpected error: ", err)
        }

        return
      }

      // @ts-ignore
      if (!grecaptcha) {
        toast.error(
          "Failed initialized recaptcha",
          { className: "toast-mesasge" }
        )
        return
      }

      // @ts-ignore
      grecaptcha.ready(async () => {
        // @ts-ignore
        const token = await grecaptcha.execute(process.env.REACT_APP_RECAPTCHAV3_SITE, { action: "submit" })
        if (!token) {
          toast.error(
            "Failed verify reCAPTCHA, please try again.",
            { className: "toast-mesasge" }
          )
          return
        }

        submitEnquiry({
          ...enquiryForm,
          verificationToken: token
        })
      })
    } catch (error: any) {
      console.error("Failed submit enquiry", error)
      toast.error(
        "Failed submit enquiry",
        { className: "toast-mesasge" }
      )
    }
  }

  React.useEffect(() => {
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        containerTypes: types
          .filter((t) => t.selected)
          .map((t) => t.name)
      }
    })
  }, [types])

  React.useEffect(() => {
    setEnquiryForm((prevState) => {
      return {
        ...prevState,
        containerItems: containers
          .filter((t) => t.selected)
          .map((t) => {
            return {
              name: t.name,
              quantity: t.quantity,
            }
          })
      }
    })
  }, [containers])

  useAutoPosition()
  useRecaptcha({
    siteId: process.env.REACT_APP_RECAPTCHAV3_SITE
  })

  return (
    <>
      <Meta>
        {{
          title: "Container Enquiry",
          description: "Contact us if you have any question or requests regarding our containers"
        }}
      </Meta>
      <Container size="md">
        <div className="grid grid-cols-12 gap-4 my-4">
          {
            containers
              .filter((container) => container.featured)
              .map((container, i: number) => {
                return (
                  <div key={`container-item-${i}`} className="col-span-6 md:!col-span-4">
                    <div className="bg-white rounded-xl border-1 shadow-md">
                      <img className="w-full h-52 object-cover rounded-tl-md rounded-tr-md"
                        width={4} height={4} src={container.thumbnail} alt={container.name} />
                      <h1 className="m-4 text-center text-xl">
                        {container.name}
                      </h1>
                    </div>
                  </div>
                )
              })
          }
        </div>

        <div className="flex flex-col gap-4 justify-center items-center my-8">
          <hr className="w-1/2 border-aglogis-red" />
          <h2 className="text-center text-3xl font-bold">Enquire Now</h2>
          <p className="text-center text-lg">
            Please complete the Enquiry Form and our team will follow up with you soon
          </p>
        </div>

        <div className="p-4 my-4 rounded-2xl bg-aglogis-primary">
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:!col-span-4 lg:!col-span-3 text-white">
              <div className="px-2">
                <h2 className="text-3xl font-bold">
                  Contact Us
                </h2>
                {
                  contacts.length > 0 &&
                  <div className="flex flex-col gap-4 my-4">
                    {
                      contacts.map((contact, i: number) => {
                        return (
                          <div key={`contact-item-${i}`} className="flex flex-row gap-4 my-4">
                            <div>{contact.icon}</div>
                            <Link to={contact.url} target={contact.target} className="text-lg">
                              {contact.name}
                            </Link>
                          </div>
                        )
                      })
                    }
                  </div>
                }
                <div className="flex flex-col gap-4 my-2">
                  <p>Scan the QR Code below on your WeChat App</p>
                  <div>
                    <Image className="w-auto h-32 object-cover"
                      src={wechatQr}
                      alt="WeChat QR Code"
                      lazy />
                  </div>
                </div>
                <div className="flex flex-col gap-4 my-2">
                  <p>Scan the QR Code below on your Botim App</p>
                  <div>
                    <Image className="w-auto h-32 object-cover"
                      src={botimQr}
                      alt="Botim QR Code"
                      lazy />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:!col-span-8 lg:!col-span-9">
              <div className="h-full p-6 rounded-2xl bg-white">
                <form onSubmit={handleEnquirySubmit}>
                  <div className="flex flex-col gap-3">
                    <Field>
                      <label htmlFor="name">
                        Name <span className="text-red-600">*</span>
                      </label>
                      <InputText
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={enquiryForm.name}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.name}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="company">
                        Company <span className="text-red-600">*</span>
                      </label>
                      <InputText
                        id="company"
                        name="company"
                        placeholder="Company"
                        value={enquiryForm.company}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.company}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="email">
                        Email <span className="text-red-600">*</span>
                      </label>
                      <InputEmail
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={enquiryForm.email}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.email}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="address">
                        Address <span className="text-red-600">*</span>
                      </label>
                      <Textarea
                        id="address"
                        name="address"
                        placeholder="Address"
                        value={enquiryForm.address}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required>
                      </Textarea>
                      <FieldNotif>{enquiryNotif.address}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="postal">
                        State/Zip <span className="text-red-600">*</span>
                      </label>
                      <InputText
                        id="postal"
                        name="postal"
                        placeholder="State/Zip"
                        value={enquiryForm.postal}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.postal}</FieldNotif>
                    </Field>
                    <Field>
                      <label htmlFor="phone">
                        Telephone / Mobile Number <span className="text-red-600">*</span>
                      </label>
                      <InputPhone
                        id="phone"
                        name="phone"
                        placeholder="Telephone / Mobile Number"
                        value={enquiryForm.phone}
                        onUpdate={(m) => {
                          setEnquiryForm((prevState) => {
                            return {
                              ...prevState,
                              phone: m.phone_number
                            }
                          })
                        }}
                        disabled={enquiryForm.loading}
                        required />
                      <FieldNotif>{enquiryNotif.phone}</FieldNotif>
                    </Field>
                    <Field>
                      <div className="flex flex-col gap-4">
                        <p>Container Information:</p>
                        <div className="grid grid-cols-12 gap-2">
                          {
                            containers.map((container, i: number) => {
                              return (
                                <div key={`container-item-${i}`} className="col-span-6 md:!col-span-4">
                                  <Checkbox
                                    id={container.name}
                                    name={container.name}
                                    label={container.name}
                                    checked={container.selected}
                                    disabled={enquiryForm.loading}
                                    onChange={(e) => {
                                      setContainers((prevState) => {
                                        return prevState.map((item) => {
                                          return {
                                            ...item,
                                            selected: (container.name === item.name) ? !item.selected : item.selected,
                                          }
                                        })
                                      })
                                    }} />
                                  <div className="w-16 ml-6 mt-2">
                                    <InputNumber
                                      id={container.name}
                                      name={container.name}
                                      value={container.quantity}
                                      appendClassNames="text-center"
                                      disabled={enquiryForm.loading}
                                      onChange={(e) => {
                                        const quantity = parseInt(e.target.value)

                                        setContainers((prevState) => {
                                          return prevState.map((item) => {
                                            return {
                                              ...item,
                                              quantity: (container.name === item.name) ? quantity : item.quantity,
                                            }
                                          })
                                        })
                                      }} />
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                        <FieldNotif>{enquiryNotif.containerItems}</FieldNotif>
                      </div>
                    </Field>
                    <Field>
                      <div className="flex flex-col gap-4">
                        <p>Container Type:</p>
                        <div className="grid grid-cols-12 gap-2">
                          {
                            types.map((type, i: number) => {
                              return (
                                <div key={`type-item-${i}`} className="col-span-6 md:!col-span-4">
                                  <Checkbox
                                    id={type.name}
                                    name={type.name}
                                    label={type.name}
                                    checked={type.selected}
                                    disabled={enquiryForm.loading}
                                    onChange={(e) => {
                                      setTypes((prevState) => {
                                        return prevState.map((item) => {
                                          return {
                                            ...item,
                                            selected: (type.name === item.name) ? !item.selected : item.selected,
                                          }
                                        })
                                      })
                                    }} />
                                </div>
                              )
                            })
                          }
                        </div>
                        <FieldNotif>{enquiryNotif.containerTypes}</FieldNotif>
                      </div>
                    </Field>
                    <Field>
                      <label htmlFor="message">
                        Additional Message/Remarks
                      </label>
                      <Textarea
                        id="message"
                        name="message"
                        placeholder="Additional Message/Remarks"
                        value={enquiryForm.message}
                        onChange={handleInputChange}
                        disabled={enquiryForm.loading}>
                      </Textarea>
                      <FieldNotif>{enquiryNotif.message}</FieldNotif>
                    </Field>
                    <Field>
                      <input type="hidden" name="formType" value={enquiryForm.formType} />
                      <div className="g-recaptcha"
                        data-sitekey={process.env.REACT_APP_RECAPTCHAV3_SITE}
                        data-size="invisible"
                        data-action="submit">
                      </div>
                    </Field>
                    <div className="flex justify-end items-center">
                      <Button type="submit" size="lg"
                        disabled={enquiryForm.loading}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
