import * as React from "react"
import { useSearchParams } from "react-router-dom"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6"
import { Link } from "@allied/react-web/Router"

type ProductPaginationProps = {
  currentPage?: number
  itemPerPage?: number
  pageRange?: number
  totalItems?: number
}

export function ProductPagination(props: ProductPaginationProps): React.JSX.Element {
  const {
    currentPage = 1,
    itemPerPage = 12,
    pageRange = 5,
    totalItems = 0
  } = props
  const [searchParams] = useSearchParams()

  const totalPages = Math.ceil(totalItems / itemPerPage)

  function getPageRange() {
    let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2))
    let endPage = startPage + pageRange - 1
    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(1, endPage - pageRange + 1)
    }
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    )
  }

  function generateSearchParams(): string {
    const reserved = new Set<string>(["page"])
    let url = ""
    for (const [key, value] of Array.from(searchParams)) {
      if (reserved.has(key)) {
        continue
      }
      url += `&${key}=${value}`
    }
    return url
  }

  return (
    <div className="flex flex-row justify-center items-center gap-4">
      {
        currentPage > 1 &&
        <Link to={`/products?page=` + (currentPage - 1) + generateSearchParams()}
          className="w-8 h-8 p-1.5 rounded-md bg-neutral-500 hover:bg-aglogis-primary text-sm text-white flex justify-center items-center">
          <FaChevronLeft className="w-4 h-4" aria-hidden="true" />
        </Link>
      }
      {
        getPageRange().map((page: number, i: number) => {
          return (
            <Link key={`page-item-${i}`} to={`/products?page=` + page + generateSearchParams()}
              className={
                "w-8 h-8 p-1.5 rounded-md hover:bg-aglogis-primary text-sm text-white flex justify-center items-center " +
                (currentPage === page ? "bg-aglogis-primary" : "bg-neutral-500")
              }>
              {page}
            </Link>
          )
        })
      }
      {
        currentPage < totalPages &&
        <Link to={`/products?page=` + (currentPage + 1) + generateSearchParams()}
          className="w-8 h-8 p-1.5 rounded-md bg-neutral-500 hover:bg-aglogis-primary text-sm text-white flex justify-center items-center">
          <FaChevronRight className="w-4 h-4" aria-hidden="true" />
        </Link>
      }
    </div>
  )
}