import * as React from "react"
import { Card } from "@allied/react-web/Card"
import { Container } from "@allied/react-web/Container"
import { Link } from "@allied/react-web/Router"
import { Meta } from "@allied/react-web/Meta"
import { useAutoPosition } from "@allied/react-web/hooks"
import { ButtonBack } from "../components/Navigation"
import { Breadcrumb } from "../components/Breadcrumb"
import { ListingService } from "../services/api/Listing"

type BrandItem = {
  name: string
  slug: string
  logo: any
}

const productService = new ListingService({
  host: `${process.env.REACT_APP_API_HOST}`
})

export default function CarBrands(): React.JSX.Element {
  const [brands, setBrands] = React.useState<BrandItem[]>([])

  React.useEffect(() => {
    async function searchBrand() {
      const searchBrand = await productService.SearchBrand({})
      if (searchBrand.error) {
        return
      }
      setBrands(searchBrand.data.items)
    }
    searchBrand()
  }, [])

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Car Brands",
          description: "Discover our wide range of car brands"
        }}
      </Meta>
      <Container size="md">
        <div className="my-6 flex flex-wrap flex-row gap-4">
          <ButtonBack>
            Back
          </ButtonBack>
          <Breadcrumb appendClassNames="font-roboto-mono font-light text-lg">
            {{
              items: [
                {
                  content: <>
                    <Link to="/" className="text-aglogis-primary">
                      Home
                    </Link>
                  </>
                },
                {
                  content: <>Car Brands</>,
                }
              ]
            }}
          </Breadcrumb>
        </div>

        <div className="my-6 flex flex-col gap-4">
          <h1 className="font-bold text-2xl md:!text-4xl">Car Brands</h1>
          <div className="grid grid-cols-12 gap-6">
            {
              brands.map((brand, i: number) => {
                return (
                  <div key={`brand-item-${i}`} className="col-span-6 sm:!col-span-4 lg:!col-span-3 xl:!col-span-2">
                    <Link to={`/car-products/${brand.slug}`}>
                      <Card size="lg">
                        <div className="flex flex-col gap-4 justify-center items-center">
                          <img src={brand.logo} alt={brand.name} className="w-40 h-40 object-cover" />
                          <h2 className="my-4 text-xl">
                            {brand.name}
                          </h2>
                        </div>
                      </Card>
                    </Link>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Container>
    </>
  )
}
