import * as React from "react"
import { useParams, useNavigate } from "react-router-dom"
import { BsDash, BsPlus } from "react-icons/bs"
import { FaChartLine } from "react-icons/fa"
import { useAutoPosition } from "@allied/react-web/hooks"
import { Accordion } from "@allied/react-web/Accordion"
import { Button } from "@allied/react-web/Button"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Link } from "@allied/react-web/Router"
import { CartContext } from "../features/Cart"
import { ButtonBack } from "../components/Navigation"
import { Breadcrumb } from "../components/Breadcrumb"
import { Info } from "../components/Page"
import { toast } from "../components/Toast"
import { RESOURCE_EXISTS, RESOURCE_NOTFOUND } from "../services/Status"
import { ComparationService } from "../services/local/Comparation"
import { ListingService } from "../services/api/Listing"
import { Price } from "../services/Commerce"

const productService = new ListingService({
  host: `${process.env.REACT_APP_API_HOST}`
})
const comparationService = new ComparationService(productService)

type ListingItem = {
  slug: string
  name: string
  thumbnail: any
  brief?: string
  description?: string
  products: Product[]
}

type Product = {
  sku?: string
  uomName?: string
  quantity?: number
  price?: Price
}

export default function ProductDetail(): React.JSX.Element {
  const [listing, setListing] = React.useState<ListingItem>(null)
  const [quantity, setQuantity] = React.useState(0)
  const { slug } = useParams()
  const { addItem } = React.useContext(CartContext)
  const navigate = useNavigate()

  async function getProduct(slug: string) {
    const getProduct = await productService.GetProduct({
      slug
    })
    if (getProduct.error) {
      return
    }
    setListing({
      ...getProduct.data,
      thumbnail: getProduct.data.thumbnail
    })
  }

  async function addComparation(slug: string) {
    const addComparation = await comparationService.AddItem({
      slug
    })
    if (addComparation.error) {
      switch (addComparation.error.code) {
        case RESOURCE_NOTFOUND:
          alert("Product is not valid")
          break
        case RESOURCE_EXISTS:
          alert("Product is already added to the compare list.")
          break
        default:
          console.error(addComparation.error)
          alert("Unexpected error happened")
      }
      return
    }

    navigate(`/compare`)
  }

  React.useEffect(() => {
    getProduct(slug)
  }, [slug])

  const handleCompareItem = () => {
    addComparation(listing.slug)
  }

  const handleQuantityUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(parseInt(e.target.value))
  }

  const handleDecreaseItem = () => {
    setQuantity((prevState) => {
      if (prevState - 1 < 0) {
        return 0
      }
      return prevState - 1
    })
  }

  const handleIncreaseItem = () => {
    setQuantity((prevState) => {
      if (prevState + 1 > 99999) {
        return 99999
      }
      return prevState + 1
    })
  }

  const handleAddToCart = () => {
    if (quantity < 1) {
      toast.warn(
        "Product quantity should be more than 1 item",
        { className: "toast-mesasge" }
      )
      return
    }

    addItem(listing.slug, quantity)
  }

  useAutoPosition()

  if (!listing) {
    return (
      <>
        <Meta>
          {{
            title: "404 Page not found",
          }}
        </Meta>

        <Info />
      </>
    )
  }

  let infoItems = [] as {
    header: React.ReactNode
    content: React.ReactNode
    opened: boolean
  }[]
  if (listing.brief) {
    infoItems.push({
      header: <p className="text-2xl md:!text-3xl">Brief</p>,
      content: <p className="font-light font-roboto-mono text-gray-600">{listing.brief}</p>,
      opened: true,
    })
  }
  if (listing.description) {
    infoItems.push({
      header: <p className="text-2xl md:!text-3xl">Description</p>,
      content: <p className="font-light font-roboto-mono text-gray-600" dangerouslySetInnerHTML={{
        __html: listing.description
      }}></p>,
      opened: true,
    })
  }

  infoItems = infoItems.map((infoItem, i: number) => {
    return {
      ...infoItem,
      opened: i === 0
    }
  })

  return (
    <>
      <Meta>
        {{
          title: listing.name,
          image: listing.thumbnail,
          description: listing.description
        }}
      </Meta>
      <Container size="md">
        <div className="my-6 flex flex-wrap flex-row gap-4">
          <ButtonBack>
            Back
          </ButtonBack>
          <Breadcrumb appendClassNames="font-roboto-mono font-light text-lg">
            {{
              items: [
                {
                  content: <>
                    <Link to="/" className="text-aglogis-primary">
                      Home
                    </Link>
                  </>
                },
                {
                  content: <>
                    <Link to="/products" className="text-aglogis-primary">
                      All Products
                    </Link>
                  </>
                },
                {
                  content: <>{listing.name}</>,
                }
              ]
            }}
          </Breadcrumb>
        </div>

        <div className="my-4 grid grid-cols-12 gap-4">
          <div className="col-span-12 md:!col-span-6 lg:!col-span-4">
            <img src={listing.thumbnail} alt={listing.name} style={{
              border: "1px solid gray",
              borderRadius: "15px",
              padding: "10px",
              display: "inline-block",
              maxWidth: "100%",
              maxHeight: "100%",
              marginBottom: "20px",
              marginRight: "20px",
              width: "500px",
            }} />
          </div>
          <div className="col-span-12 md:!col-span-6 lg:!col-span-8">
            <div className="flex flex-col gap-3">
              <h1 className="text-2xl md:!text-3xl font-bold">
                {listing.name}
              </h1>
              <div className="flex flex-col gap-1">
                {
                  listing.products.map((product, i: number) => {
                    return (
                      <React.Fragment key={`listing-product-sku-${i}`}>
                        <p className="text-lg">
                          <span>SKU#: {product.sku || "N/A"}</span>
                          {
                            product.uomName &&
                            <span>
                              , UOM: {product.uomName}
                            </span>
                          }

                          {
                            product.quantity &&
                            <span>
                              , Qty: {product.quantity}
                            </span>
                          }

                          {
                            product.price &&
                            <span>
                              , Price: {`${product.price.currency.symbol}${product.price.amount}`}
                            </span>
                          }
                        </p>
                      </React.Fragment>
                    )
                  })
                }
              </div>
              <div className="block">
                <button className="flex flex-row justify-start items-center gap-2" onClick={handleCompareItem}>
                  <FaChartLine className="w-6 h-6" aria-hidden="true" />
                  <span className="text-lg">Compare Products</span>
                </button>
              </div>
            </div>

            <hr className="my-4 w-full text-aglogis-primary" />

            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-2">
                <Button onClick={handleDecreaseItem}>
                  <BsDash className="w-5 h-5" aria-hidden="true" />
                </Button>
                <input className="w-32 flex justify-center items-center text-center p-2 rounded-md border border-gray-400"
                  value={quantity}
                  onChange={handleQuantityUpdate} />
                <Button onClick={handleIncreaseItem}>
                  <BsPlus className="w-5 h-5" aria-hidden="true" />
                </Button>
              </div>
              <div>
                <Button size="lg" onClick={handleAddToCart}>
                  Add to Cart
                </Button>
              </div>
            </div>
          </div>
        </div>

        {
          infoItems.length > 0 &&
          <div className="my-2">
            <Accordion alwaysOpen>
              {{
                items: infoItems
              }}
            </Accordion>
          </div>
        }
      </Container>
    </>
  )
}
