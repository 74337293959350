import * as React from "react"
import { toast } from "../../components/Toast"
import { CartService } from "../../services/local/Cart"
import { ListingService } from "../../services/api/Listing"

const productService = new ListingService({
  host: `${process.env.REACT_APP_API_HOST}`
})
const cartService = new CartService(productService)

type Cart = {
  items: CartItem[]
  total_items: number
  total_quantities: number
  addItem?: (slug: string, quantity: number) => void
  removeItem?: (slug: string) => void
  updateItem?: (slug: string, quantity: number) => void
  increaseItem?: (slug: string) => void
  decreaseItem?: (slug: string) => void
  clearItem?: () => void
}

type CartItem = {
  quantity: number
  product: ProductItem
}

type ProductItem = {
  sku: string
  slug: string
  name: string
  thumbnail: any
  description: string
  detail: string
  information: string
  uom: string
  principals: string[]
  brands: string[]
  types: string[]
}

export const CartContext = React.createContext<Cart>({
  items: [],
  total_items: 0,
  total_quantities: 0,
})
const { Consumer, Provider } = CartContext

type CartProviderProps = {
  children?: React.ReactNode
}

export function CartProvider(props: CartProviderProps): React.JSX.Element {
  const [cart, setCart] = React.useState({
    items: [],
    total_items: 0,
    total_quantities: 0
  })

  async function loadCart() {
    const searchItem = await cartService.SearchItem({})
    if (searchItem.error) {
      return
    }

    setCart({
      items: searchItem.data.items,
      total_items: searchItem.data.items.length,
      total_quantities: searchItem.data.items.reduce((prevVal: number, cartItem: CartItem, i: number) => {
        return prevVal + cartItem.quantity
      }, 0)
    })
  }

  async function addItem(slug: string, quantity: number) {
    const addItem = await cartService.AddItem({
      slug,
      quantity,
    })
    if (addItem.error) {
      toast.error(
        `Failed add item to the cart: ${addItem.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    loadCart()

    toast.success(
      "Item has been added into cart",
      { className: "toast-mesasge" }
    )
  }

  async function removeItem(slug: string) {
    const removeItem = await cartService.RemoveItem({
      slug
    })
    if (removeItem.error) {
      toast.error(
        `Failed remove item from the cart: ${removeItem.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    loadCart()

    toast.success(
      `Success remove item from the cart`,
      { className: "toast-mesasge" }
    )
  }

  async function updateItem(slug: string, quantity: number) {
    const updateItem = await cartService.UpdateItem({
      slug,
      quantity
    })
    if (updateItem.error) {
      toast.error(
        `Failed update item on the cart: ${updateItem.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    loadCart()

    toast.success(
      `Success update item on the cart`,
      { className: "toast-mesasge" }
    )
  }

  async function increaseItem(slug: string) {
    const increaseItem = await cartService.IncreaseItem({
      slug,
      quantity: 1
    })
    if (increaseItem.error) {
      toast.error(
        `Failed increase item on the cart: ${increaseItem.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    loadCart()

    toast.success(
      `Success increase item on the cart`,
      { className: "toast-mesasge" }
    )
  }

  async function decreaseItem(slug: string) {
    const decreaseItem = await cartService.DecreaseItem({
      slug,
      quantity: 1
    })
    if (decreaseItem.error) {
      toast.error(
        `Failed decrease item on the cart: ${decreaseItem.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    loadCart()

    toast.success(
      `Success decrease item on the cart`,
      { className: "toast-mesasge" }
    )
  }

  async function clearItem() {
    const clearItem = await cartService.ClearItem()
    if (clearItem.error) {
      toast.error(
        `Failed clear cart item: ${clearItem.error.message}`,
        { className: "toast-mesasge" }
      )
      return
    }

    loadCart()
  }

  React.useEffect(() => {
    loadCart()
  }, [])

  return (
    <Provider value={{
      items: cart.items,
      total_items: cart.total_items,
      total_quantities: cart.total_quantities,
      addItem,
      removeItem,
      updateItem,
      increaseItem,
      decreaseItem,
      clearItem,
    }}>
      {props.children}
    </Provider>
  )
}

export {
  Consumer as CartConsumer
}
