import {
  SearchProductParam, SearchProductResult,
  VerifyProductParam, VerifyProductResult,
  GetProductParam, GetProductResult,
  SearchBrandParam, SearchBrandResult,
  SearchPrincipalParam, SearchPrincipalResult,
} from "../Product"
import {
  Status, ACTION_SUCCESS, ACTION_FAILED,
  RESOURCE_NOTFOUND, UNEXPECTED_ERROR
} from "../Status"
import {
  Price
} from "../Commerce"

import Audi from "./../../assets/image/brands/Audi-logo.png"
import Austin from "./../../assets/image/brands/Austin-logo.png"
import AlfaRomeo from "./../../assets/image/brands/Alfa-Romeo-logo.png"
import AstonMartin from "./../../assets/image/brands/Aston-Martin-logo.png"
import Bentley from "./../../assets/image/brands/Bentley-logo.png"
import BMW from "./../../assets/image/brands/BMW-logo.png"
import BYD from "./../../assets/image/brands/BYD-logo.png"
import Chevrolet from "./../../assets/image/brands/Chevrolet-logo.png"
import Chrysler from "./../../assets/image/brands/chrysler-logo.png"
import Citroen from "./../../assets/image/brands/Citroen-logo.png"
import Daihatsu from "./../../assets/image/brands/Daihatsu-logo.png"
import Ferrari from "./../../assets/image/brands/Ferrari-logo.png"
import Fiat from "./../../assets/image/brands/Fiat-logo.png"
import Ford from "./../../assets/image/brands/Ford-logo.png"
import Hino from "./../../assets/image/brands/Hino-logo.png"
import Honda from "./../../assets/image/brands/Honda-logo.png"
import Hyundai from "./../../assets/image/brands/Hyundai-logo.png"
import Infiniti from "./../../assets/image/brands/Infiniti-logo.png"
import Isuzu from "./../../assets/image/brands/Isuzu-logo.png"
import Jaguar from "./../../assets/image/brands/Jaguar-logo.png"
import Jeep from "./../../assets/image/brands/jeep-logo.png"
import Kia from "./../../assets/image/brands/Kia-logo.png"
import Lamborghini from "./../../assets/image/brands/Lamborghini-logo.png"
import LandRover from "./../../assets/image/brands/LandRover-logo.png"
import Lexus from "./../../assets/image/brands/Lexus-logo.png"
import Mazda from "./../../assets/image/brands/Mazda-logo.png"
import MG from "./../../assets/image/brands/MG-logo.png"
import MAN from "./../../assets/image/brands/MAN-logo.png"
import Maserati from "./../../assets/image/brands/Maserati-logo.png"
import Mercedes from "./../../assets/image/brands/Mercedes-Benz-logo.png"
import Mini from "./../../assets/image/brands/Mini-logo.png"
import Mitsubishi from "./../../assets/image/brands/Mitsubishi-logo.png"
import Nissan from "./../../assets/image/brands/Nissan-logo.png"
import Opel from "./../../assets/image/brands/Opel-logo.png"
import Perodua from "./../../assets/image/brands/Perodua-logo.png"
import Porsche from "./../../assets/image/brands/Porsche-logo.png"
import Peugeot from "./../../assets/image/brands/Peugeot-logo.png"
import Proton from "./../../assets/image/brands/Proton-logo.png"
import RR from "./../../assets/image/brands/Rolls-Royce-logo.png"
import Rover from "./../../assets/image/brands/Rover-logo.png"
import Saab from "./../../assets/image/brands/Saab.png"
import Tesla from "./../../assets/image/brands/Tesla-logo.png"
import Toyota from "./../../assets/image/brands/Toyota-logo.png"
import Yutong from "./../../assets/image/brands/Yutong.png"
import Suzuki from "./../../assets/image/brands/Suzuki-logo.png"
import Ssangyong from "./../../assets/image/brands/Ssangyong.png"
import Scania from "./../../assets/image/brands/Scania.png"
import SEAT from "./../../assets/image/brands/SEAT.png"
import Skoda from "./../../assets/image/brands/Skoda.png"
import Subaru from "./../../assets/image/brands/Subaru-logo.png"
import UD from "./../../assets/image/brands/UD.png"
import Volvo from "./../../assets/image/brands/Volvo.png"
import Volkswagen from "./../../assets/image/brands/Volkswagen.png"
import Renault from "./../../assets/image/brands/Renault-logo.png"

import boschPlaceholder from "./../../assets/image/products/boschPlaceholder.png"
import libertyPlaceholder from "./../../assets/image/products/libertyPlaceholder.png"
import palmaPlaceholder from "./../../assets/image/products/palmaPlaceholder.png"
import bosch from "./../../assets/image/principals/bosch.png"
// import liberty from "./../../assets/image/principals/liberty.png"
import palma from "./../../assets/image/principals/palma.jpg"

export const brands = [
  {
    name: "Alfa Romeo",
    slug: "alfa-romeo",
    logo: AlfaRomeo,
  },
  {
    name: "Aston Martin",
    slug: "aston-martin",
    logo: AstonMartin,
  },
  {
    name: "Audi",
    slug: "audi",
    logo: Audi,
    featured: true,
  },
  {
    name: "Austin",
    slug: "austin",
    logo: Austin,
  },
  {
    name: "Bentley",
    slug: "bentley",
    logo: Bentley,
  },
  {
    name: "BMW",
    slug: "bmw",
    logo: BMW,
    featured: true,
  },
  {
    name: "BYD",
    slug: "byd",
    logo: BYD,
  },
  {
    name: "Chevrolet",
    slug: "chevrolet",
    logo: Chevrolet,
  },
  {
    name: "Chrysler",
    slug: "chrysler",
    logo: Chrysler,
  },
  {
    name: "Citroen",
    slug: "citroen",
    logo: Citroen,
  },
  {
    name: "Daihatsu",
    slug: "daihatsu",
    logo: Daihatsu,
  },
  {
    name: "Ferrari",
    slug: "ferrari",
    logo: Ferrari,
  },
  {
    name: "Fiat",
    slug: "fiat",
    logo: Fiat,
  },
  {
    name: "Ford",
    slug: "ford",
    logo: Ford,
  },
  {
    name: "Hino",
    slug: "hino",
    logo: Hino,
  },
  {
    name: "Honda",
    slug: "honda",
    logo: Honda,
    featured: true,
  },
  {
    name: "Hyundai",
    slug: "hyundai",
    logo: Hyundai,
    featured: true,
  },
  {
    name: "Infiniti",
    slug: "infiniti",
    logo: Infiniti,
  },
  {
    name: "Isuzu",
    slug: "isuzu",
    logo: Isuzu,
  },
  {
    name: "Jaguar",
    slug: "jaguar",
    logo: Jaguar,
  },
  {
    name: "Jeep",
    slug: "jeep",
    logo: Jeep,
  },
  {
    name: "KIA",
    slug: "kia",
    logo: Kia,
    featured: true,
  },
  {
    name: "Lamborghini",
    slug: "lamborghini",
    logo: Lamborghini,
  },
  {
    name: "Land Rover",
    slug: "land-rover",
    logo: LandRover,
  },
  {
    name: "Lexus",
    slug: "lexus",
    logo: Lexus,
    featured: true,
  },
  {
    name: "MAN",
    slug: "man",
    logo: MAN,
  },
  {
    name: "Maserati",
    slug: "maserati",
    logo: Maserati,
  },
  {
    name: "Mazda",
    slug: "mazda",
    logo: Mazda,
    featured: true,
  },
  {
    name: "Mercedes-Benz",
    slug: "mercedes-benz",
    logo: Mercedes,
    featured: true,
  },
  {
    name: "MG",
    slug: "mg",
    logo: MG,
  },
  {
    name: "MINI",
    slug: "mini",
    logo: Mini,
  },
  {
    name: "Mitsubishi",
    slug: "mitsubishi",
    logo: Mitsubishi,
  },
  {
    name: "Nissan",
    slug: "nissan",
    logo: Nissan,
  },
  {
    name: "Opel",
    slug: "opel",
    logo: Opel,
  },
  {
    name: "Perodua",
    slug: "perodua",
    logo: Perodua,
  },
  {
    name: "Peugeot",
    slug: "peugeot",
    logo: Peugeot,
  },
  {
    name: "Porsche",
    slug: "porsche",
    logo: Porsche,
  },
  {
    name: "Proton",
    slug: "proton",
    logo: Proton,
  },
  {
    name: "Renault",
    slug: "renault",
    logo: Renault,
  },
  {
    name: "Rolls-Royce",
    slug: "rolls-royce",
    logo: RR,
  },
  {
    name: "Rover",
    slug: "rover",
    logo: Rover,
  },
  {
    name: "Saab",
    slug: "saab",
    logo: Saab,
  },
  {
    name: "Scania",
    slug: "scania",
    logo: Scania,
  },
  {
    name: "SEAT",
    slug: "seat",
    logo: SEAT,
  },
  {
    name: "Skoda",
    slug: "skoda",
    logo: Skoda,
  },
  {
    name: "Ssangyong",
    slug: "ssangyong",
    logo: Ssangyong,
  },
  {
    name: "Subaru",
    slug: "subaru",
    logo: Subaru,
  },
  {
    name: "Suzuki",
    slug: "suzuki",
    logo: Suzuki,
  },
  {
    name: "Tesla",
    slug: "tesla",
    logo: Tesla,
  },
  {
    name: "Toyota",
    slug: "toyota",
    logo: Toyota,
    featured: true,
  },
  {
    name: "UD",
    slug: "ud",
    logo: UD,
  },
  {
    name: "Volkswagen",
    slug: "volkswagen",
    logo: Volkswagen,
  },
  {
    name: "Volvo",
    slug: "volvo",
    logo: Volvo,
  },
  {
    name: "Yutong",
    slug: "yutong",
    logo: Yutong,
  },
]

const principals = [
  {
    name: "Bosch",
    slug: "bosch",
    description: "Bosch has been a leader in battery production since 1922, continuously innovating to meet the growing demand for high-quality and reliable battery power in the automotive industry. With today's cars being fitted with more and more components that run on electricity, having a high-quality and reliable battery power is ever more essential.",
    brief: "Reliable automotive batteries for superior performance, including Start-Stop and Alternator Management Systems.",
    logo: bosch,
    website: "https://www.bosch.com.sg/",
    featured: true,
  },
  // {
  //   name: "Liberty",
  //   slug: "liberty",
  //   description: "Liberty Worldwide is a fast-growing multinational corporation with a product portfolio of high-performance Automobile, Industrial & Marine lubricants and greases of world-class quality.",
  //   brief: "Lubricants and greases worldwide through quality, innovation, and global partnerships. Preferred choice for businesses seeking exceptional lubrication solutions",
  //   logo: liberty,
  //   website: "http://www.libertylubricants.com/",
  //   featured: true,
  // },
  {
    name: "Palma",
    slug: "palma",
    description: "Palma Battery Co., Ltd. offers high-quality battery solutions for automotive, industrial, and consumer electronics applications, delivering exceptional performance and reliability. Visit our website to explore our comprehensive product lineup and find the perfect battery solution for your needs.",
    brief: "Offers high-quality battery solutions for automotive, industrial, and consumer electronics applications, delivering exceptional performance and reliability.",
    logo: palma,
    website: "https://www.palma-battery.net/",
    featured: true,
  }
]

const DEFAULT_THUMBNAIL = "/image/placeholder-400x400.jpg"
const BOSCH_THUMBNAIL = boschPlaceholder
const PALMA_THUMBNAIL = palmaPlaceholder
const LIBERTY_THUMBNAIL = libertyPlaceholder

type ListingSource = {
  slug: string
  name: string
  thumbnails: ThumbnailSource[]
  brief?: string
  description?: string
  brands: string[]
  tags: string[]
  categories: string[]
  products: ProductSource[]
}

type ThumbnailSource = {
  url: string
}

type ProductSource = {
  sku?: string
  uom_name?: string
  quantity?: number
  price?: Price
}

type Listing = {
  slug: string
  name: string
  thumbnail: any
  brief?: string
  description?: string
  principals: string[]
  brands: string[]
  types: string[]
  products: Product[]
}

type Product = {
  sku: string
  uomName: string
  quanamey: number
  price?: Price
}

type Brand = {
  name: string
  slug: string
  logo: any
  featured?: boolean
}

type Principal = {
  name: string
  slug: string
  description: string
  brief: string
  logo: any
  website: string
  featured?: boolean
}

type ListingConfig = {
  host: string
}

export class ListingService {
  private config: ListingConfig
  private brands: Brand[] = []
  private principals: Principal[] = []

  constructor(config: ListingConfig) {
    this.config = config
    this.brands = brands
    this.principals = principals
  }

  // @todo: rename to `SearchListing` soon
  public async SearchProduct(p: SearchProductParam): Promise<SearchProductResult> {
    try {
      const {
        page = 1,
        total_items = 12,
        slugs = [],
        principals = [],
        brands = [],
        types = [],
        keyword,
      } = p

      const url = `${this.config.host}/script/search-listing.php`
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        redirect: "follow",
        mode: "no-cors"
      })
      if (!response.ok) {
        return {
          error: new Status("failed search listing", ACTION_FAILED),
        }
      }

      const body = await response.json()
      const isBodyValid = body && body.code && body.data
      if (!isBodyValid) {
        return {
          error: new Status("failed decode listing", ACTION_FAILED),
        }
      }

      let listings: Listing[] = body.data.items.map((listing: ListingSource) => {
        return {
          ...listing,
          thumbnail: Array.isArray(listing.thumbnails) && listing.thumbnails.length > 0 ?
            listing.thumbnails[0].url : getDefaultThumbnail(listing.brands.map((item) => item.toLowerCase())),

          principals: listing.brands ? listing.brands.map((item) => item.toLowerCase()) : [],
          brands: listing.tags ? listing.tags.map((item) => item.toLowerCase()) : [],
          types: listing.categories ? listing.categories.map((item) => item.toLowerCase()) : [],

          products: listing.products ? listing.products.map((product) => {
            return {
              sku: product.sku || undefined,
              uomName: product.uom_name || undefined,
              quantity: product.quantity || undefined,
            }
          }) : []
        }
      })

      if (slugs.length > 0) {
        const slugDict = new Set<string>()
        slugs.forEach((slug) => {
          slugDict.add(slug)
        })

        listings = listings.filter((listing) => {
          return slugDict.has(listing.slug)
        })
      }

      if (principals.length > 0) {
        const principalsDict = new Set<string>()
        principals.forEach((principal) => {
          principalsDict.add(principal.toLowerCase())
        })

        listings = listings.filter((listing) => {
          return listing.principals.some((principal) => {
            return principalsDict.has(principal)
          })
        })
      }

      if (brands.length > 0) {
        const brandsDict = new Set<string>()
        brands.forEach((brand) => {
          brandsDict.add(brand.toLowerCase())
        })

        listings = listings.filter((listing) => {
          return listing.brands.some((brand) => {
            return brandsDict.has(brand)
          })
        })
      }

      if (types.length > 0) {
        const typesDict = new Set<string>()
        types.forEach((type) => {
          typesDict.add(type.toLowerCase())
        })

        listings = listings.filter((listing) => {
          return listing.types.some((type) => {
            return typesDict.has(type)
          })
        })
      }

      if (keyword) {
        const sanitizedKeyword = keyword.toLowerCase()
        listings = listings.filter((listing) => {
          const hasSku = Array.isArray(listing.products) ?
            listing.products
              .filter((product) => product.sku
                .toLowerCase()
                .includes(sanitizedKeyword))
              .length > 0
            : false

          return (
            listing.name.toLowerCase().includes(sanitizedKeyword) ||
            listing.principals.map((principal) => principal.toLowerCase()).includes(sanitizedKeyword) ||
            listing.brands.map((brand) => brand.toLowerCase()).includes(sanitizedKeyword) ||
            listing.types.map((type) => type.toLowerCase()).includes(sanitizedKeyword) ||
            hasSku
          )
        })
      }

      const totalItems = listings.length
      const last = page * total_items
      const first = last - total_items

      listings = listings.slice(first, last)

      return {
        success: new Status("success search listing", ACTION_SUCCESS),
        data: {
          sumary: {
            page,
            total_items: totalItems
          },
          items: listings,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  // @todo: rename to `VerifyListing` soon
  public async VerifyProduct(p: VerifyProductParam): Promise<VerifyProductResult> {
    try {
      const verifications: { exists: boolean }[] = []

      const searchProduct = await this.SearchProduct({
        slugs: p.slugs
      })
      if (searchProduct.error) {
        return {
          error: searchProduct.error
        }
      }

      const products = new Map<string, any>()
      searchProduct.data.items.forEach((product) => {
        products.set(product.slug, product)
      })

      p.slugs.forEach((slug) => {
        verifications.push({
          exists: products.has(slug)
        })
      })

      return {
        success: new Status("success verify listings", ACTION_SUCCESS),
        data: {
          items: verifications,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  // @todo: rename to `GetListing` soon
  public async GetProduct(p: GetProductParam): Promise<GetProductResult> {
    try {
      const {
        slug
      } = p

      const url = `${this.config.host}/script/get-listing.php?slug=${slug}`
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        redirect: "follow",
        mode: "no-cors"
      })
      if (!response.ok) {
        return {
          error: new Status("failed search listing", ACTION_FAILED),
        }
      }

      const body = await response.json()
      const isBodyValid = body && body.code && body.data
      if (!isBodyValid) {
        return {
          error: new Status("failed decode listing", ACTION_FAILED),
        }
      }

      const isNotFound = body.code === RESOURCE_NOTFOUND
      if (isNotFound) {
        return {
          error: new Status("listing not found", RESOURCE_NOTFOUND)
        }
      }

      const listing = body.data as ListingSource

      return {
        success: new Status("success get listing", ACTION_SUCCESS),
        data: {
          slug: listing.slug,
          name: listing.name,
          brief: listing.brief || undefined,
          description: listing.description || undefined,
          principals: listing.brands ? listing.brands.map((item) => item.toLowerCase()) : [],
          brands: listing.tags ? listing.tags.map((item) => item.toLowerCase()) : [],
          types: listing.categories ? listing.categories.map((item) => item.toLowerCase()) : [],
          thumbnail: Array.isArray(listing.thumbnails) && listing.thumbnails.length > 0 ?
            listing.thumbnails[0].url : getDefaultThumbnail(listing.brands.map((item) => item.toLowerCase())),
          products: listing.products ? listing.products.map((product) => {
            return {
              sku: product.sku || undefined,
              uomName: product.uom_name || undefined,
              quantity: product.quantity || undefined,
              price: product.price || undefined,
            }
          }) : [],
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchBrand(p: SearchBrandParam): Promise<SearchBrandResult> {
    try {
      const {
        featured = false,
      } = p

      let brands: Brand[] = this.brands

      if (featured) {
        brands = brands.filter((brand) => {
          return brand.featured === true
        })
      }

      return {
        success: new Status("success search brand", ACTION_SUCCESS),
        data: {
          items: brands,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchPrincipal(p: SearchPrincipalParam): Promise<SearchPrincipalResult> {
    try {
      const {
        featured = false,
      } = p

      let principals: Principal[] = this.principals
      if (featured) {
        principals = principals.filter((principal) => {
          return principal.featured === true
        })
      }

      return {
        success: new Status("success search principal", ACTION_SUCCESS),
        data: {
          items: principals,
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

}

function getDefaultThumbnail(items: string[]): string {
  const brands = new Set(items)

  let thumbnail = DEFAULT_THUMBNAIL
  if (brands.has("bosch")) {
    thumbnail = BOSCH_THUMBNAIL
  } else if (brands.has("palma")) {
    thumbnail = PALMA_THUMBNAIL
  } else if (brands.has("liberty")) {
    thumbnail = LIBERTY_THUMBNAIL
  }

  return thumbnail
}