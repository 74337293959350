import * as React from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useAutoPosition } from "@allied/react-web/hooks"
import { Button } from "@allied/react-web/Button"
import { Card } from "@allied/react-web/Card"
import { Container } from "@allied/react-web/Container"
import { Meta } from "@allied/react-web/Meta"
import { Link } from "@allied/react-web/Router"
import { ButtonBack } from "../components/Navigation"
import { Breadcrumb } from "../components/Breadcrumb"
import { ProductFilter, ProductPagination, FilterItem } from "../features/Product"
import { SearchProductParam } from "../services/Product"
import { ListingService } from "../services/api/Listing"

const productService = new ListingService({
  host: `${process.env.REACT_APP_API_HOST}`
})

type ListingItem = {
  slug: string
  name: string
  thumbnail: any
  brief?: string
}

export default function Products(): React.JSX.Element {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [productFilter, setProductFilter] = React.useState({
    open: false,
    page: 1,
    total_items: 12,
    principals: [],
    brands: [],
    types: []
  })
  const [product, setProduct] = React.useState({
    page: 0,
    total_items: 0,
    items: [] as ListingItem[]
  })
  const selectedFilters = productFilter.principals.concat(productFilter.brands.concat(productFilter.types))

  async function searchProduct(param: SearchProductParam) {
    const searchProduct = await productService.SearchProduct(param)
    if (searchProduct.error) {
      return
    }
    setProduct({
      page: searchProduct.data.sumary.page,
      total_items: searchProduct.data.sumary.total_items,
      items: searchProduct.data.items.map((listing) => {
        return {
          ...listing,
        }
      }),
    })
  }

  React.useEffect(() => {
    searchProduct({
      page: productFilter.page,
      total_items: productFilter.total_items,
      principals: productFilter.principals,
      brands: productFilter.brands,
      types: productFilter.types,
    })
  }, [
    productFilter.page, productFilter.total_items,
    productFilter.principals, productFilter.brands,
    productFilter.types
  ])

  React.useEffect(() => {
    const page = parseInt(searchParams.get("page"))
    if (isNaN(page) || page === 0) {
      return
    }

    if (page < 1) {
      navigate("/products?page=1")
      return
    }

    setProductFilter((prevState) => {
      return {
        ...prevState,
        page
      }
    })
  }, [navigate, searchParams])

  React.useEffect(() => {
    let principals: string[] = []
    if (searchParams.has("principals") && searchParams.get("principals") !== "") {
      principals = searchParams.get("principals").split(",")
    }

    let types: string[] = []
    if (searchParams.has("types") && searchParams.get("types") !== "") {
      types = searchParams.get("types").split(",")
    }

    let brands: string[] = []
    if (searchParams.has("brands") && searchParams.get("brands") !== "") {
      brands = searchParams.get("brands").split(",")
    }

    setProductFilter((prevState) => {
      return {
        ...prevState,
        principals,
        types,
        brands
      }
    })
  }, [searchParams])

  const handleFilterOpen = () => {
    setProductFilter((prevState) => {
      return {
        ...prevState,
        open: true
      }
    })
  }

  const handleFilterClose = () => {
    setProductFilter((prevState) => {
      return {
        ...prevState,
        open: false
      }
    })
  }

  const handleFilterApply = ({ filters }: { filters: FilterItem[] }) => {
    setProductFilter((prevState) => {
      return {
        ...prevState,
        open: false
      }
    })

    const params: Record<string, string> = {
      page: productFilter.page.toString()
    }

    const principals = filters
      .filter((filter) => filter.type === "principal")
      .map((filter) => filter.value)
    if (principals.length > 0) {
      params[`principals`] = principals.join(",")
    }

    const brands = filters
      .filter((filter) => filter.type === "brand")
      .map((filter) => filter.value)
    if (brands.length > 0) {
      params[`brands`] = brands.join(",")
    }

    const types = filters
      .filter((filter) => filter.type === "type")
      .map((filter) => filter.value)
    if (types.length > 0) {
      params[`types`] = types.join(",")
    }

    setSearchParams(params)
  }

  function generateProductMeta() {
    let title = ""
    let shortTitle = ""
    let description = ""

    const onlyPrincipal = productFilter.principals.length === 1 &&
      productFilter.brands.length === 0 &&
      productFilter.types.length === 0
    const onlyBrand = productFilter.brands.length === 1 &&
      productFilter.principals.length === 0 &&
      productFilter.types.length === 0
    if (selectedFilters.length === 0) {
      title = "All Products"
      shortTitle = "All Products"
      description = "Discover our extensive collection of products from our trusted partners. Find the list of items for your company here."
    } else if (onlyPrincipal) {
      title = `${productFilter.principals[0]} Products`
      shortTitle = `${productFilter.principals[0]} Products`
      description = "Discover our extensive collection of products from our trusted partners. Find the list of items for your company here."
    } else if (onlyBrand) {
      title = `${productFilter.brands[0]} Products`
      shortTitle = `${productFilter.brands[0]} Products`
      description = "Discover our extensive collection of products from our trusted partners. Find the list of items for your company here."
    } else {
      title = `Filtered Products: ` + selectedFilters.join(", ")
      shortTitle = "Filtered Products"
      description = "Tailor your shopping experience and explore products that best suit your needs."
    }

    return {
      title,
      shortTitle,
      description
    }
  }

  const { title, shortTitle, description } = generateProductMeta()

  useAutoPosition()

  return (
    <>
      <Meta>
        {{
          title: "Products",
          description: "Discover our extensive collection of products from our trusted partners. Find the list of items for your company here."
        }}
      </Meta>
      <Container size="md">
        <div className="my-6 flex flex-wrap flex-row gap-4">
          <ButtonBack>
            Back
          </ButtonBack>
          <Breadcrumb appendClassNames="font-roboto-mono font-light text-lg">
            {{
              items: [
                {
                  content: <>
                    <Link to="/" className="text-aglogis-primary">
                      Home
                    </Link>
                  </>
                },
                {
                  content: <>{shortTitle}</>,
                }
              ]
            }}
          </Breadcrumb>
        </div>

        <div className="my-6 flex flex-row gap-4">
          <div className="w-full">
            <h1 className="mb-4 font-bold text-3xl">
              {title}
            </h1>
            <p className="w-full md:!w-1/2 text-2xl">
              {description}
            </p>
          </div>
          <div>
            {
              productFilter.open &&
              <div className="w-full md:!w-1/3 lg:!w-1/4 overflow-auto fixed top-0 right-0 bottom-0 z-10 bg-white shadow-md">
                <ProductFilter
                  onClose={handleFilterClose}
                  onApply={handleFilterApply}
                  principals={productFilter.principals}
                  brands={productFilter.brands}
                  types={productFilter.types} />
              </div>
            }
            <Button size="lg" onClick={handleFilterOpen}>
              Filter
            </Button>
          </div>
        </div>

        <div className="my-6 grid grid-cols-12 gap-4 md:!gap-6 auto-rows-fr">
          {
            product.items.length > 0 ?
              product.items.map((product, i: number) => {
                return (
                  <div key={`product-item-${i}`} className="col-span-6 lg:!col-span-4">
                    <Card size="lg" appendClassNames="h-full border-gray-400 hover:border-aglogis-primary">
                      <div className="p-2">
                        <Link to={`/products/${product.slug}`}>
                          <img src={product.thumbnail} alt={product.name} />
                        </Link>
                        <div className="min-h-56 md:!min-h-48 lg:!min-h-30 xl:!min-h-28 flex flex-col gap-2">
                          <Link to={`/products/${product.slug}`} className="font-semibold text-lg md:!text-xl text-black hover:text-aglogis-primary">
                            {product.name}
                          </Link>
                          {
                            product.brief &&
                            <p className="text-base text-gray-600">
                              {product.brief}
                            </p>
                          }
                        </div>
                      </div>
                    </Card>
                  </div>
                )
              })
              :
              <div className="col-span-12">
                <p className="text-xl text-center">
                  No products found for the selected criteria.
                </p>
              </div>
          }
        </div>

        <div className="my-6">
          <ProductPagination
            currentPage={product.page}
            totalItems={product.total_items} />
        </div>
      </Container>
    </>
  )
}
