import * as React from "react"
import { To } from "react-router-dom"
import { BsCart3 } from "react-icons/bs"
import { ButtonLink } from "@allied/react-web/Button"

type ButtonCartProps = {
  children?: React.ReactNode
  totalItems?: number
  to: To
}

export function ButtonCart(props: ButtonCartProps): React.JSX.Element {
  const {
    totalItems = 0
  } = props

  return (
    <ButtonLink to={props.to} theme="aglogis" variant="secondary">
      <div className="flex flex-row justify-center items-center gap-1">
        <BsCart3 aria-hidden="true" className="w-4 h-4" />
        {props.children}
        <span className="absolute top-1 right-4 p-1.5 rounded-3xl bg-aglogis-primary text-xs text-white font-semibold">
          {totalItems}
        </span>
      </div>
    </ButtonLink>
  )
}
