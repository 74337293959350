import {
  AddItemParam, AddItemResult,
  RemoveItemParam, RemoveItemResult,
  UpdateItemParam, UpdateItemResult,
  ClearItemResult,
  IncreaseItemParam, IncreaseItemResult,
  DecreaseItemParam, DecreaseItemResult,
  SearchItemParam, SearchItemResult,
  GetSummaryResult
} from "../Cart"
import {
  ProductService
} from "../Product"
import {
  Status, ACTION_SUCCESS,
  RESOURCE_NOTFOUND,
  UNEXPECTED_ERROR,
} from "../Status"

type CartItem = {
  slug: string
  quantity: number
}

export class CartService {

  constructor(private productService: ProductService) { }

  public async AddItem(p: AddItemParam): Promise<AddItemResult> {
    try {
      const verifyProduct = await this.productService.VerifyProduct({
        slugs: [p.slug]
      })
      if (verifyProduct.error) {
        return {
          error: verifyProduct.error
        }
      }
      const valid = verifyProduct.data.items.some((item) => item.exists)
      if (!valid) {
        return {
          error: new Status("item is not available", RESOURCE_NOTFOUND)
        }
      }

      let { items: cartItems } = await this.getCart()

      const exists = cartItems.findIndex((item) => item.slug === p.slug)
      if (exists !== -1) {
        cartItems[exists] = {
          slug: p.slug,
          quantity: cartItems[exists].quantity + p.quantity,
        }
      } else {
        cartItems.push({
          slug: p.slug,
          quantity: p.quantity
        })
      }

      localStorage.setItem("cart", JSON.stringify({
        items: cartItems
      }))

      return {
        success: new Status("success add item", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async RemoveItem(p: RemoveItemParam): Promise<RemoveItemResult> {
    try {
      let { items: cartItems } = await this.getCart()

      cartItems = cartItems.filter((item) => item.slug !== p.slug)

      localStorage.setItem("cart", JSON.stringify({
        items: cartItems
      }))

      return {
        success: new Status("success remove item", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async UpdateItem(p: UpdateItemParam): Promise<UpdateItemResult> {
    try {
      let { items: cartItems } = await this.getCart()

      const exists = cartItems.findIndex((item) => item.slug === p.slug)
      if (exists === -1) {
        return {
          error: new Status("item not available", RESOURCE_NOTFOUND),
        }
      }

      cartItems[exists] = {
        quantity: p.quantity,
        slug: p.slug,
      }

      localStorage.setItem("cart", JSON.stringify({
        items: cartItems
      }))

      return {
        success: new Status("success update item", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async ClearItem(): Promise<ClearItemResult> {
    try {
      localStorage.setItem("cart", JSON.stringify({
        items: []
      }))
      return {
        success: new Status("success clear item", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async IncreaseItem(p: IncreaseItemParam): Promise<IncreaseItemResult> {
    try {
      let { items: cartItems } = await this.getCart()

      const exists = cartItems.findIndex((item) => item.slug === p.slug)
      if (exists === -1) {
        return {
          error: new Status("item not available", RESOURCE_NOTFOUND),
        }
      }

      const cartItem = cartItems[exists]
      if (cartItem.quantity + p.quantity >= 99999) {
        cartItems[exists] = {
          quantity: 99999,
          slug: cartItem.slug,
        }
      } else {
        cartItems[exists] = {
          quantity: cartItem.quantity + p.quantity,
          slug: cartItem.slug,
        }
      }

      localStorage.setItem("cart", JSON.stringify({
        items: cartItems
      }))

      return {
        success: new Status("success decrease item", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async DecreaseItem(p: DecreaseItemParam): Promise<DecreaseItemResult> {
    try {
      let { items: cartItems } = await this.getCart()

      const exists = cartItems.findIndex((item) => item.slug === p.slug)
      if (exists === -1) {
        return {
          error: new Status("item not available", RESOURCE_NOTFOUND),
        }
      }

      const cartItem = cartItems[exists]
      if (cartItem.quantity - p.quantity <= 0) {
        cartItems = cartItems.filter((item) => item.slug !== p.slug)
      } else {
        cartItems[exists] = {
          quantity: cartItem.quantity - p.quantity,
          slug: cartItem.slug,
        }
      }

      localStorage.setItem("cart", JSON.stringify({
        items: cartItems
      }))

      return {
        success: new Status("success decrease item", ACTION_SUCCESS),
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async SearchItem(p: SearchItemParam): Promise<SearchItemResult> {
    try {
      let { items: cartItems } = await this.getCart()

      if (cartItems.length === 0) {
        return {
          success: new Status("success search items", ACTION_SUCCESS),
          data: {
            items: [],
            sumary: {
              page: 1,
              total_items: 0
            }
          }
        }
      }

      const searchProduct = await this.productService.SearchProduct({
        slugs: cartItems.map((cartItem) => cartItem.slug)
      })
      if (searchProduct.error) {
        return {
          error: searchProduct.error
        }
      }
      const products = new Map<string, any>()
      searchProduct.data.items.forEach((product) => {
        products.set(product.slug, product)
      })

      const items = []
      for (let i = 0; i < cartItems.length; i++) {
        const cartItem = cartItems[i]
        const product = products.get(cartItem.slug)
        items.push({
          product,
          quantity: cartItem.quantity
        })
      }

      return {
        success: new Status("success search items", ACTION_SUCCESS),
        data: {
          items,
          sumary: searchProduct.data.sumary
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  public async GetSummary(): Promise<GetSummaryResult> {
    try {
      let { items: cartItems } = await this.getCart()

      return {
        success: new Status("success get summary", ACTION_SUCCESS),
        data: {
          total_items: cartItems.length,
          total_quantities: cartItems.reduce((prevVal: number, cartItem: CartItem, i: number) => {
            return prevVal + cartItem.quantity
          }, 0)
        }
      }
    } catch (err: any) {
      return {
        error: new Status(err.message, UNEXPECTED_ERROR)
      }
    }
  }

  private async getCart(): Promise<{items: CartItem[]}> {
    try {
      let cartItems: CartItem[] = []
      const cart = localStorage.getItem("cart")
      if (cart) {
        const { items } = JSON.parse(cart)
        cartItems.push(...items)
      }

      return {
        items: cartItems
      }
    } catch (err: any) {
      return {
        items: []
      }
    }
  }

}
